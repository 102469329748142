@use '@tsp-ui/core/sass/utils';

.main {
  max-width: 900px;
}

.root {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  margin: auto;
  padding-inline: utils.spacing(3);
}

/* CloserCapacityCard */

.card {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 1fr 230px 1fr;
  gap: utils.spacing(2);
  flex: 1 0 auto;
  padding-block: utils.spacing(0.5);
}

.capacities {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: utils.spacing(2);
}

.buttons {
  display: flex;
  align-items: center;
  justify-self: end;
  gap: utils.spacing(2);
}

.expandedRow {
  background: var(--app-color_background);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: utils.spacing(1);
  border-bottom-right-radius: var(--app-border-radius);
  border-bottom-left-radius: var(--app-border-radius);
}

/* CapacityOverridesSection */

.capacityOverridesSection {
  display: contents;

  & > * {
    width: 400px;
  }
}

.capacityOverridesHeader {
  display: flex;
  align-items: center;
  gap: utils.spacing(2);
  justify-content: space-between;
}

.divider {
  margin-block: utils.spacing(-0.5);
  width: calc(400px + #{utils.spacing(2)})
}

.table {
  padding: 0;
  border: none;
}

.noOverridesPaper {
  margin-top: utils.spacing(1.5);
  padding: utils.spacing(1);
}

/* LabeledCapacityDisplay */

.capacity {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* CapacityDisplay */

.iconContainer {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  justify-content: center;
}

/* OverrideTableRow */

.overrideButtons {
  white-space: nowrap;
  padding-right: 0 !important;
}
