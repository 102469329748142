/*
  Creates variables for use within the project. Any changes made here
  should also be made in mui-theme.ts (if applicable).
 */

@use "sass:color";

$header-height: 64px;

$background-color: #FAFAFA;
$primary-color: #46779B;

:root {
  --app-color_primary: #{$primary-color};
  --app-color_primary-light: #559bc3;

  --app-color_secondary: #9b6946;
  --app-color_success: #4CAF50;
  --app-color_error: #D32F2F;

  --app-color_background: #{$background-color};
  --app-color_background-medium: #{color.adjust($background-color, $blackness: 10%)};

  --app-color_border: rgba(0, 0, 0, 0.12);
  --app-color_disabled_border: rgba(0, 0, 0, 0.26);

  --app-color_text: rgba(0, 0, 0, 0.87);
  --app-color_text-secondary: rgba(0, 0, 0, 0.54);

  --app-border-radius: 4px;
  --app-font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
