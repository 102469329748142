@use '@tsp-ui/core/sass/utils';

.root {
  height: 48px;
  margin-right: auto;
  display: flex;
  align-items: center;
  gap: utils.spacing(2);

  & > img {
    height: 100%;
  }
}
