@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: utils.spacing(2);

  & > div {
    background-color: #FFF;
  }
}

.auditorIcon {
  transform: rotate(-90deg);
}
