@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.paper {
  padding: utils.spacing(4);
  margin-bottom: utils.spacing(4);
}
