@use '@tsp-ui/core/sass/utils';
@use 'src/sass/app-theme';
@use './components/MainNav.module';

.root {
  height: 100vh;
  display: grid;
  grid-template:
    "nav header" #{app-theme.$header-height}
    "nav main"/#{MainNav.$drawerWidth} 1fr;
}

.headerContainer {
  grid-area: header;
  background-color: white;
  box-shadow: 0 5px 6px -3px rgba(0, 0, 0, 0.2);
  max-width: calc(100vw - #{MainNav.$drawerWidth});

  .toolbar {
    height: #{app-theme.$header-height};
    gap: utils.spacing(3);
    justify-content: space-between;
    box-sizing: content-box;
    padding-inline: utils.spacing(6);
  }
}

.headerButton {
  color: var(--app-color_text-secondary);
}

.accountButtonContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: utils.spacing(2);
}

.activityLogButton {
  display: none;
  margin-left: utils.spacing(-1);
}

.routeLoader {
  grid-area: main;
}
