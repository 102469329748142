@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1, 2);
  display: grid;
  grid-template-columns: 1fr auto;
  gap: utils.spacing(1);

  .name {
    align-self: center;
  }

  .iconButtons {
    justify-self: end;
  }
}

.divider {
  grid-column: 1/-1;
  margin: utils.spacing(0, -1, 1, -1)
}

.button {
  margin-left: utils.spacing(-1);
  justify-self: flex-start;
}

.createdDate {
  align-self: center;
}

.productsInfoContainer {
  grid-column: 1/-1;
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
}
