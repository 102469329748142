@use '@tsp-ui/core/sass/utils';

.root {
  grid-area: main; // TODO
  padding: utils.spacing(3);
  max-width: 1366px;
  margin-inline: auto;
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: utils.spacing(2);
  align-items: center;
  margin-inline: auto;
  margin-bottom: utils.spacing(3);
}

.title {
  display: flex;
  gap: utils.spacing(2);
  align-items: baseline;
}

.calendarControls {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, auto);
  gap: utils.spacing(2);
}

.calendarControl {
  border-radius: 4px;
  overflow: hidden;

  &_select {
    padding-top: utils.spacing(1);
  }

  &_input {
    background-color: var(--app-color_background-medium);
    border-radius: 4px;

    &:before {
      border: 0;
    }

    input {
      padding-top: utils.spacing(1);
    }
  }
}

.settingsButton {
  display: none; // TODO post-demo
  justify-self: end;
}

.dayHeader {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-inline: auto;
  border-radius: 4px;
  background-color: var(--app-color_background-medium);
  margin-bottom: utils.spacing(2);

  & > div {
    text-align: center;
    padding-block: utils.spacing(1);

    &:not(:last-child) {
      border-right: 1px solid var(--app-color_background);
    }
  }
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  border-style: solid;
  border-color: var(--app-color_background-medium);
  border-width: 0 1px 1px 0;
  border-radius: 4px;
  margin: auto;
  overflow: hidden;
}










