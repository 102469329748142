@use '@tsp-ui/core/sass/utils';

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);
}

.rolesHeader {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
  grid-column: 1/-1;
}

.roleCheckboxContainer {
  display: grid;
  grid-template-columns: 1fr 60px 60px 60px;
  gap: utils.spacing(1);
  align-items: center;
  grid-column: 1/-1;
}

.roleCheckboxHeader {
  display: contents;

  & > * {
    align-self: stretch;
    padding: utils.spacing(0.5, 1);
    margin-inline: utils.spacing(-0.5);
    background-color: var(--app-color_background-medium);

    &:not(:first-child) {
      text-align: center;
    }

    &:first-child {
      border-top-left-radius: var(--app-border-radius);
      border-bottom-left-radius: var(--app-border-radius);
      margin-left: 0;
    }

    &:last-child {
      border-top-right-radius: var(--app-border-radius);
      border-bottom-right-radius: var(--app-border-radius);
      margin-right: 0;
    }
  }
}

.userDetailsContainer {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
  grid-column: 1/-1;
  padding: utils.spacing(1);
}

.dialogPaper {
  min-width: 500px;
}

.checkbox {
  justify-self: center;
}

.info {
  grid-column: 1/-1;
  justify-self: center;
}

.productName {
  margin-left: utils.spacing(1);
}
