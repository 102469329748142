@use '@tsp-ui/core/sass/utils';

.root {
  height: 150px;
  border: 1px solid var(--app-color_background-medium);
  border-right-width: 0;
  border-bottom-width: 0;
  background-color: white;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 184px;

  &.otherMonth {
    opacity: 0.3;
  }

  &:hover.otherMonth {
    opacity: 1;
  }

  &:first-child {
    border-top-left-radius: 4px;
  }

  &:nth-child(7) {
    border-top-right-radius: 4px;
  }

  &:nth-child(29) { // TODO vary based on # of weeks
    border-bottom-left-radius: 4px;
  }
}

.dayInfo {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: utils.spacing(1);
  padding-right: utils.spacing(0.5);
}

.date {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34.5px;
  width: 34.5px;
  background-color: var(--app-color_background);
  border-style: solid;
  border-color: var(--app-color_background-medium);
  border-width: 0 1px 1px 0;
  border-bottom-right-radius: 4px;
  font-weight: 500;

  &.today {
    background-color: var(--app-color_primary);
    border-color: var(--app-color_primary);
    color: white;
  }
}

.statusIcons {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: center;
}

.iconTypography {
  gap: utils.spacing(0.5);
  border-radius: 4px;

  & > * {
    color: var(--app-color_text-secondary);
  }
}

.progressContainer {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - #{utils.spacing(4)});
  margin: auto;

  .progress {
    width: 100%;
  }
}

.overCapacity {
  margin-top: -20px;
}

.progressCaption {
  margin-top: utils.spacing(0.5)
}

.volume {
  margin-bottom: utils.spacing(0.5);
  align-self: center;
}

.alert {
  padding: utils.spacing(0, 2, 1);
  width: 100%;
  bottom: 0;
  position: absolute;
}

.alertBody {
  display: flex;
  align-items: center;
  justify-content: center;
}
