@use '@tsp-ui/core/sass/utils';


.dialogContent {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
  min-width: 1250px;
  gap: utils.spacing(6);
}

.contentHeader {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
  margin-bottom: utils.spacing(2);
}

.nextDayButtons {
  display: grid;
  grid-template-columns: 160px auto auto;
  justify-items: end;
  align-items: end;
  gap: utils.spacing(1);
}

.expandButton {
  margin-block: utils.spacing(-1);
}

.closingCapacitySection {
  min-width: 300px;
}

.capacityPaper {
  padding: utils.spacing(2, 2, 1, 2);
}

.dateField {
  margin-left: utils.spacing(1);
  width: 127px;
}

.alignCenter {
  align-self: center;
}

.progressIndicator {
  display: flex;
  padding-inline: utils.spacing(1);
}

.closingWith {
  padding: utils.spacing(0.5, 1, 0, 1);
}

.closingCapacities {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);

  .capacityPaper:not(:first-child) {
    margin-inline: utils.spacing(1);
    padding: utils.spacing(1, 1, 0.5, 1);
  }
}




.loanTableExpandedContent {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(3);
}

.loanDetails {
  display: grid;
  align-items: start;
  grid-template-columns: 1fr 1fr auto;
  gap: utils.spacing(3);
}

.closingDetailsPaper {
  display: flex;
  align-items: center;
  gap: utils.spacing(2);
  background-color: var(--app-color_background-medium);
  border-radius: 4px;
  padding: utils.spacing(0.5, 0.5, 0.5, 1);
}

.dateTimeline {
  color: #01374C;
  margin-bottom: utils.spacing(1);
  margin-right: utils.spacing(1);

  .icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block: utils.spacing(0.5);
    margin-inline: utils.spacing(-0.25);
  }

  .dateProgress {
    position: relative;
  }

  .dateLine {
    position: absolute;
    top: -2px;
    height: 8px;
    width: 2px;
    background-color: #01374C;

    &:nth-child(2) {
      left: 9px; // widthOfIcon / 2 - marginLeft - widthOfTicker / 2 = 24px / 2 - 2px - 2px / 2 = 9px
    }

    &:nth-child(3) {
      left: calc((100% - 18px) / 5 + 9px);
    }

    &:nth-child(4) {
      left: calc((100% - 18px) / 5 * 2 + 9px);
    }

    &:nth-child(5) {
      left: calc((100% - 18px) / 5 * 3 + 9px);
    }

    &:nth-child(6) {
      left: calc((100% - 18px) / 5 * 4 + 9px);
    }

    &:nth-child(7) {
      right: 9px;
    }
  }

  .dates {
    display: flex;
    justify-content: space-between;
    margin-top: utils.spacing(0.5);
    margin-inline: utils.spacing(-1);
  }
}

.captions {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: utils.spacing(4);
  margin-top: utils.spacing(0.5);
}

.noLoansFound {
  display: flex;
  justify-content: center;
}

.loanAssociateName {
  display: flex;
  align-items: baseline;
  gap: utils.spacing(0.5);

  & > svg {
    position: relative;
    top: utils.spacing(0.5);
  }
}
