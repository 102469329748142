@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  gap: utils.spacing(6);
  align-items: flex-start;
}

.loanData {
  display: grid;
  grid-template-columns: auto auto;
  gap: utils.spacing(4);
  margin-top: utils.spacing(1);
}

.entries {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}
