@use '@tsp-ui/core/sass/utils';
@use 'src/sass/app-theme';
@use 'sass:color';

/*
  This file contains overrides for css variables defined by amplify to style
  the auth components according to the ppm theme.
 */

[data-amplify-theme] {
  --amplify-colors-brand-primary-10: #{color.scale(app-theme.$primary-color, $lightness: 70%)} !important;
  --amplify-colors-brand-primary-20: #{color.scale(app-theme.$primary-color, $lightness: 60%)} !important;
  --amplify-colors-brand-primary-30: #{color.scale(app-theme.$primary-color, $lightness: 50%)} !important;
  --amplify-colors-brand-primary-40: #{color.scale(app-theme.$primary-color, $lightness: 40%)} !important;
  --amplify-colors-brand-primary-50: #{color.scale(app-theme.$primary-color, $lightness: 30%)} !important;
  --amplify-colors-brand-primary-60: #{color.scale(app-theme.$primary-color, $lightness: 20%)} !important;
  --amplify-colors-brand-primary-70: #{color.scale(app-theme.$primary-color, $lightness: 10%)} !important;
  --amplify-colors-brand-primary-80: var(--app-color_primary) !important;
  --amplify-colors-brand-primary-90: #{color.scale(app-theme.$primary-color, $blackness: 10%)} !important;
  --amplify-colors-brand-primary-100: #{color.scale(app-theme.$primary-color, $blackness: 20%)} !important;
  --amplify-fonts-default-static: var(--app-font-family) !important;
  --amplify-fonts-default-variable: var(--app-font-family) !important;
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-field-gap: 0;
}

[class*=amplify] {
  font-family: var(--app-font-family) !important;
}

/* Hide skip button on verify user screen */
[data-amplify-authenticator-verifyuser] button[data-variation=link] {
  display: none;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding-block: var(--amplify-space-medium);
}

.amplify-tabs {
  display: none !important;
}

.amplify-button[data-variation=primary] {
  font-weight: 500 !important;
  text-transform: uppercase;
}

[data-amplify-router] {
  border-radius: var(--app-border-radius);
}

.custom-sign-in-header {
  padding-top: var(--amplify-space-medium);
  padding-left: var(--amplify-space-xl);
}
