@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(3);
}

.formPaper {
  min-width: 500px;
  padding: utils.spacing(2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  position: relative;
}

.productOrGroupFields {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.buttons {
  grid-column: 1/-1;
  display: flex;
  justify-content: flex-end;
  gap: utils.spacing(2);
  margin-top: utils.spacing(1);
}

.addButtonContainer {
  align-self: flex-end;
}

.widgetPreviewButton {
  justify-self: end;
}
