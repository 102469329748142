@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  grid-column: 1/-1;
}

.group {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: utils.spacing(0.5, 1);

  &.old {
    border-color: var(--app-color_error);
  }

  &.new {
    border-color: var(--app-color_success);
  }
}

.productDiffContainer {
  display: contents;
}
