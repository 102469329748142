@use '@tsp-ui/core/sass/utils';

.content {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.fieldLabel {
  margin-bottom: utils.spacing(-2);
}

.nameFields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);
}

.issueLabel {
  margin-top: utils.spacing(2);
}

.subject {
  margin-bottom: utils.spacing(-1);
}

.captchaTerms {
  margin-right: auto;
}
