@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex: 0 1 auto;
  gap: utils.spacing(3);
  width: 100%;
}

.section {
  max-height: 100%;
  background-color: transparent !important;
}

.formSection {
  flex: 0 0 550px;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);
}

.header {
  grid-column: 1/-1;
  margin-bottom: utils.spacing(-1);
}

.checkboxes {
  grid-column: 1/-1;
}
