.root {
  position: relative;
  background-color: rgba(0, 0, 0, 0.06);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

  &:hover, &.focused {
    background-color: rgba(0, 0, 0, 0.09);
  }

  &:before, &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:before {
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }

  &:hover:before, &.focused:before {
    border-bottom-color: rgba(0, 0, 0, 0.87);
  }

  &:after {
    border-bottom: 2px solid var(--app-color_primary);

    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }

  &.focused:after {
    transform: none;
  }
}

.cardNumberElement {
  padding: 12px 12px 8px 12px;
}
