@use '@tsp-ui/core/sass/utils';

.instructions {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
}

.codePaper {
  overflow: hidden;
  margin-top: utils.spacing(2);

  pre {
    margin: 0 !important;
    padding: utils.spacing(1) !important;
  }
}

.widgetTypeButtons {
  display: grid;
  grid-template-columns: 200px 200px;
  gap: utils.spacing(2);
  margin-block: utils.spacing(2);
  justify-content: center;
}

.widgetTypeButton {
  flex-direction: column;
  gap: utils.spacing(2);
  padding: utils.spacing(2);

  .icon {
    font-size: utils.spacing(5);
  }
}

.feedSelectContainer {
  display: grid;
  grid-template-columns: 300px;
  justify-content: center;
  margin-block: utils.spacing(2);
}
