@use '@tsp-ui/core/sass/utils';

.fileContainer {
  display: flex;
  gap: utils.spacing(3);
  align-items: stretch;
  justify-content: center;
  margin-block: utils.spacing(3);
}

.fileCard {
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto auto;
  gap: utils.spacing(3);
  align-items: center;
  justify-items: center;
  padding: utils.spacing(3);
  width: 230px;
  overflow: hidden;
  word-break: break-all;

  .closeButton {
    position: absolute;
    right: 0;
    top: 0;
  }

  &.done {
    border-color: var(--app-color_success);
  }

  & > * {
    z-index: 1;
  }
}

.helperText {
  padding-inline: utils.spacing(1);
  text-align: center;
}

.progressContainer {
  color: var(--app-color_text-secondary);
  position: relative;
  height: 40px;
}

.successIcon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doneGrowContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.doneGrow {
  position: relative;
  top: -25%;
  left: -25%;
  background-color: rgb(237, 247, 237);
  border-radius: 50%;
  height: 150%;
  width: 150%;
  transform: scale(0);
  transition: transform 250ms ease 100ms;

  &.show {
    transform: scale(1);
  }
}
