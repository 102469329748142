@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: utils.spacing(3);
}

.header {
  position: relative;
}

.cancelButton {
  position: absolute;
  left: 0;
}
