@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1);
  display: flex;
  justify-content: space-between;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.actionsContainer {
  justify-content: center;
}

.actions {
  align-self: flex-end;
}

.createdDate {
  white-space: nowrap;
  text-align: right;
}

.strike {
  text-decoration: line-through;
  color: var(--app-color_text-secondary);
}
