@use '@tsp-ui/core/sass/utils';

.content {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.card {
  width: 300px;
  padding: utils.spacing(1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(1);
}

.iconTypography {
  grid-column: 1/-1;
  display: flex;
  gap: utils.spacing(1);
  align-items: center;
}
