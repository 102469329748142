@use '@tsp-ui/core/sass/utils';

.root {
  position: relative;
  padding: utils.spacing(3, 2, 2, 2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  margin-top: utils.spacing(1);
}

.header {
  position: absolute;
  top: 0;
  left: utils.spacing(1);
  width: calc(100% - #{utils.spacing(2)});
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;

  & > * {
    background-color: white;
    padding-inline: utils.spacing(0.5);
  }
}

.headerLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-inline: utils.spacing(1);
  border-radius: var(--app-border-radius);
}

