@use '@tsp-ui/core/sass/utils';

$drawerWidth: 85px;

.logoToolbar {
  padding: 0;
  justify-content: center;
  color: #FFF;

  img {
    width: 100%;
  }
}

.drawer {
  width: $drawerWidth;
  background-color: var(--app-color_primary);
  color: #FFF;
  border-right: none;
  box-shadow: var(--shadow-10);

  .listItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: utils.spacing(2, 3);
    transition: background-color ease 250ms;
  }

  .label {
    margin-top: utils.spacing(0.5);
    line-height: 1.1;
  }

  .selected {
    background-color: var(--app-color_primary-light);
  }
}

.auditorIcon {
  transform: rotate(-90deg);
}
