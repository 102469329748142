@use '@tsp-ui/core/sass/utils';

.queryResults {
  flex: 1 1 auto;
  align-self: flex-start;
  max-height: 100%;
  position: relative;

  &.isJson {
    align-self: stretch;
    flex: 1 1 auto;
    background-color: white;
    min-width: 700px;
  }

  & pre {
    // The react-syntax-highlighter library uses inline styles
    // on this pre element, so we need !important on these rules
    // so they override the inline styles.
    height: 100% !important;
    margin: 0 !important;
    border-radius: var(--app-border-radius) !important;
    padding: utils.spacing(1) !important;
  }
}

.loadingText {
  margin-bottom: utils.spacing(1);
}

.buttonContainer {
  display: flex;
  align-items: center;
  margin: utils.spacing(-1, 0);
}

.results {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.result {
  padding: utils.spacing(0, 1);

  .header {
    margin-bottom: utils.spacing(0.5);
  }

  .mainRow {
    display: flex;
    align-items: center;
    gap: utils.spacing(2);

    .programID {
      margin-left: auto;
      white-space: nowrap;
    }
  }

  .expandedRow {
    margin: utils.spacing(0, -1);
    border-top: 1px solid var(--app-color_border);
    padding: utils.spacing(1);
    box-shadow: inset 0 6px 6px rgb(0 0 0 / 2%);

    display: grid;
    grid-template-columns: 1fr auto;
    align-items: start;
    gap: utils.spacing(1, 2);

    .filteredRatesSwitch {
      margin-inline: utils.spacing(1, 0);
    }

    .notes {
      grid-column: 1/-1;
    }

    .noteList {
      padding-left: utils.spacing(3);
      margin: 0;
    }
  }
}

.programDetails {
  white-space: nowrap;
}

.rateTable {
  display: inline-block;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  justify-self: start;
}

.filteredRate {
  text-decoration: line-through;
  color: var(--app-color_text-secondary);
}

.productsButton {
  align-self: center;
}

.previewButton {
  margin-block: utils.spacing(-1);
}

.additionalConfigText {
  margin-block: utils.spacing(1);
}

.refreshLoader {
  margin: utils.spacing(1);
}

.allRatesFilteredContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: utils.spacing(1);
}
