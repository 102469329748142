@use '@tsp-ui/core/sass/utils';

.root {
  position: relative;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.loader {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}

.pagination {
  margin-top: utils.spacing(1);
}

.paginationItems {
  justify-content: center;
}

.paginationSkeleton {
  margin-top: utils.spacing(1);
  justify-self: center;
}
