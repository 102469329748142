@use '@tsp-ui/core/sass/utils';

.popoverPaper {
  padding: utils.spacing(2);
}

.entities {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(0.5);
}
