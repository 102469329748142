@use '@tsp-ui/core/sass/utils';

.dividerContainer {
  display: flex;
  align-items: center;
  width: utils.spacing(3);
  padding-inline: utils.spacing(0.5);

  & > hr {
    width: 100%;
  }
}
