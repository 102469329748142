@use '@tsp-ui/core/sass/utils';

.root {
  flex-direction: column;
  gap: utils.spacing(2);
  padding: utils.spacing(2);
  color: var(--app-color_text-secondary);
  border-color: var(--app-color_border);
  position: relative;

  &.selected {
    border-color: var(--app-color_secondary);
  }

  &:hover {
    border-color: var(--app-color_border);
  }

  &.selected, &:hover {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
  }
}

.radio {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.icon {
  font-size: utils.spacing(5);
}
