@use '@tsp-ui/core/sass/utils';
@use 'src/sass/app-theme';

.main {
  display: grid;
  grid-template-rows: max-content 1fr;
  height: calc(100vh - #{app-theme.$header-height});
  max-width: 1000px;

  .root {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100%;
    gap: utils.spacing(4);
    overflow: hidden;

    & > * {
      overflow: hidden;
    }
  }
}

.checkbox {
  margin-block: utils.spacing(-1);
}

.filterField {
  width: 230px;
}
