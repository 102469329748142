@use '@tsp-ui/core/sass/utils';

.changeSummary {
  margin-left: auto;
  display: grid;
  grid-template-columns: 30px 130px 100px 130px;
  gap: utils.spacing(0.5);

  b {
    font-weight: 500;
  }
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.card {
  align-self: stretch;
}

.expandedRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: utils.spacing(1);
}
