@use '@tsp-ui/core/sass/utils';
@use 'MainNav.module';

.main {
  grid-area: main;
  margin: auto;
  padding: utils.spacing(0, 6, 3, 6);
  max-width: calc(1366px + #{utils.spacing(12)});
  width: 100%;
  height: 100%;
}

.toolbar {
  display: flex;
  align-items: center;
  gap: utils.spacing(3);
}

.header {
  margin-block: utils.spacing(3);

  display: flex;
  align-items: center;
  gap: utils.spacing(3);
}

.headerActionsContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: utils.spacing(2);
  flex: 0 0 auto;
}

.pageMessageContainer {
  position: relative;
  overflow: hidden;
  pointer-events: none;

  & > * {
    pointer-events: all;
  }
}

.main.centered {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .toolbar {
    align-self: stretch;
  }

  & .header {
    width: 100%;
    justify-content: center;
  }

  & .pageMessageContainer {
    position: absolute;
  }

  & .headerActionsContainer {
    position: absolute;
    right: 0;
  }
}

.help {
  cursor: help;
  margin-right: utils.spacing(1);
}

.centeredLoader {
  align-self: stretch;
}
