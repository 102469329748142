@use '@tsp-ui/core/sass/utils';

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);

  .fullWidth {
    grid-column: 1/-1;
  }
}

.primaryContactContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: utils.spacing(1);
}
