@use '@tsp-ui/core/sass/utils';

.popover {
  padding: utils.spacing(0.5, 2, 2, 2);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr 1fr;
  gap: utils.spacing(2);
}

.header {
  grid-column: 1/-1;
  display: flex;
  gap: utils.spacing(2);
  justify-content: space-between;
  align-items: center;
  color: #FFF;
  background-color: var(--app-color_primary);
  margin: utils.spacing(-0.5, -2, 0, -2);
  padding-inline: utils.spacing(1);
}

.appButton {
  padding: utils.spacing(2);
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: center;
  justify-content: center;
  justify-items: center;
  gap: utils.spacing(1);
  border-color: var(--app-color_border);
  color: var(--app-color_text);
  text-transform: none;
}

.auditorIcon {
  transform: rotate(-90deg);
}
