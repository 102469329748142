@use '@tsp-ui/core/sass/utils';

.expandedContent {
  margin: utils.spacing(1);
  border-radius: var(--app-border-radius);
  background-color: var(--app-color_background);
  padding: utils.spacing(1, 0.75, 1, 1.5);

  .scrollContainer {
    overflow: auto;
    max-height: 350px;
    padding-right: utils.spacing(0.75);

    &::-webkit-scrollbar {
      width: utils.spacing(1);
      margin-left: utils.spacing(1);

      &-track {
        background-color: transparent;
      }

      &-thumb {
        border: 1px solid transparent;
        background-clip: content-box;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: utils.spacing(0.5);

        &:hover {
          background-color: rgba(0, 0, 0, 0.4);
        }

        &:active {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}


