@use '@tsp-ui/core/sass/utils';

.header {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
}

.root {
  min-width: 500px;
  padding: utils.spacing(0.5, 2);
}
