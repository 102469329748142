@use '@tsp-ui/core/sass/utils';

.form {
  display: flex;
  gap: utils.spacing(2);
  margin-top: utils.spacing(3);
}

.adornment {
  margin-right: 0;
}
