@use '@tsp-ui/core/sass/utils';

.root {
  display: inline-grid;
  grid-template-columns: auto auto;
  align-items: start;
  gap: utils.spacing(6);
}

.leftPanel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: utils.spacing(3);
}

.card {
  padding: utils.spacing(1, 2, 2, 2);
  display: inline-block;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content {
  margin-top: utils.spacing(1);
  display: grid;
  grid-template-columns: auto auto;
  gap: utils.spacing(2, 4);
}

.configNeeded {
  margin-top: utils.spacing(1);
}

.cardButton {
  margin: utils.spacing(0.5, 0, -1, -1);
}

.billingDetails {
  justify-self: start;
  min-width: 300px;
}

.enterBillingButton {
  margin-left: utils.spacing(-1);
  margin-bottom: utils.spacing(-1);
}

.subscriptionInfo {
  display: flex;
  align-items: center;
  gap: utils.spacing(0.5);
  margin-bottom: utils.spacing(1);
}

.subscriptionStatusIcon {
  margin-left: auto;
}

.ellieConfigContent {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.ellieConfigValues {
  display: flex;
  justify-content: space-between;
  gap: utils.spacing(4);
}

.userMappings {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.userMappingAlert {
  max-width: 350px;
}

.addButton {
  align-self: flex-start;
  margin-top: utils.spacing(0.5);
  margin-left: utils.spacing(-1);
}
