@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1, 2);
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto auto 1fr;
  gap: utils.spacing(1);
  min-width: max-content;
  height: 100%;

  .name {
    align-self: center;
  }

  .iconButtons {
    justify-self: end;
  }
}

.divider {
  grid-column: 1/-1;
  margin-inline: utils.spacing(-1);
  margin-top: utils.spacing(-2);
}

.summary {
  grid-column: 1/-1;
  margin-block: utils.spacing(1);
  display: grid;
  grid-template-columns: max-content auto auto;
  align-items: baseline;
  gap: utils.spacing(2);
  // make it so that the elements inside the summary don't go outside the box (just in case)
  overflow: hidden;
  max-width: 450px;
}

.fico {
  align-self: flex-end;
  margin-bottom: utils.spacing(1);
}

.button {
  margin-left: utils.spacing(-1);
  justify-self: flex-start;
  align-self: flex-end;
}
