@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: utils.spacing(3);
  align-items: start;
}

@media screen and (max-width: 1500px) {
  .root {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 1080px) {
  .root {
    grid-template-columns: 1fr;
  }
}
