@use '@tsp-ui/core/sass/utils';

.accountMenu {
  padding: utils.spacing(2);
}

.nameContainer {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  margin-bottom: utils.spacing(3);
}

.actionsContainer {
  display: flex;
  gap: utils.spacing(2);
  align-items: flex-end;

  .logOutButton {
    margin-left: auto;
  }
}

.avatar {
  background-color: var(--app-color_primary);
}
