@use '@tsp-ui/core/sass/utils';

.feedTypeLabel {
  margin-bottom: utils.spacing(0.5);
}

.feedTypeContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);
}
