@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-columns: 320px 1fr;
  gap: utils.spacing(3);
  align-items: start;
}

.detailsPaper {
  padding: utils.spacing(1, 2);
}

.calcSettings {
  justify-self: start;
  max-width: 500px;
}

.defaultValuesLabel {
  margin-top: utils.spacing(1);
}

.header {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
  margin-right: utils.spacing(-1);
  margin-bottom: utils.spacing(1);
}

.defaultValuesRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-block: utils.spacing(1);
}

.justify {
  text-align: justify;
}

.rateSelect {
  margin-bottom: utils.spacing(3);
}

.widgetPreviewsHeading {
  grid-column: 1/-1;
}

.colorPickerItem {
  display: flex;
  align-items: center;
  gap: utils.spacing(1);
  border-radius: var(--app-border-radius);

  &:not(:last-child) {
    margin-bottom: utils.spacing(2);
  }
}

.colorPickerItemColor {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid var(--app-color_border);
}

.colorInput {
  width: 26px;
  height: 26px;
  border-radius: 4px;
  background-color: transparent;
  border-color: var(--app-color_border);
}

.cancelButton {
  margin-right: utils.spacing(-1);
  margin-left: auto;
}
