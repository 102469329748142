@use '@tsp-ui/core/sass/utils';

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);

  .description {
    grid-column: 1/-1;
  }
}
