@use '@tsp-ui/core/sass/utils';

.root {
  padding-top: utils.spacing(4);
}

.titleInputContainer {
  align-self: stretch;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: utils.spacing(2);
  align-items: center;
}

.saveButton {
  margin-top: utils.spacing(-2);
}

.feedProductCardContainer {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.addProductButton {
  align-self: flex-end;
  margin-block: utils.spacing(-1);
}

.buttonContainer {
  display: flex;
}
