@use '@tsp-ui/core/sass/utils';

.loader {
  margin-top: utils.spacing(-3);
}

.havingTroubleLabel {
  margin-top: utils.spacing(3);
}

.usernameFields {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: utils.spacing(1);
  align-items: end;
  padding: var(--amplify-space-medium) var(--amplify-components-authenticator-form-padding);
}

.atSymbol {
  margin-bottom: utils.spacing(1);
}

.fullWidth {
  grid-column: 1/-1 !important;
}

.button {
  margin-top: utils.spacing(2) !important;
}

.error {
  margin-top: utils.spacing(1) !important;
}

form[data-amplify-authenticator-signin] {
  display: none;
}

form[data-amplify-authenticator-resetpassword] {
  .usernameFields + :global(.amplify-flex),
  .usernameFields ~ * button[type="submit"] {
    display: none;
  }
}
