@use '@tsp-ui/core/sass/utils';


.cardContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.card {
  padding: utils.spacing(1, 2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: utils.spacing(2);

  .buttons {
    white-space: nowrap;
  }
}
