@use '@tsp-ui/core/sass/utils';

.root {
  margin-top: utils.spacing(3);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(4);
}

.paymentButton {
  flex-direction: column;
  gap: utils.spacing(2);
  padding: utils.spacing(2);
  color: var(--app-color_text-secondary);
}

.icon {
  font-size: utils.spacing(5);
}
