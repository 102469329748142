@use '@tsp-ui/core/sass/utils';

.dialogPaper {
  width: 100%;
}

.content {
  overflow: hidden;
  display: flex;
}
