@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1, 2);
  display: grid;
  grid-template-columns: 1fr auto;
  gap: utils.spacing(1, 2);
}

.actions {
  margin-block: utils.spacing(-1);
  justify-self: end;
}

.divider {
  margin-inline: utils.spacing(-1);
}

.scenariosPopoverPaper {
  padding: utils.spacing(2);
}

.scenarios {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(.25);
}
