@use '@tsp-ui/core/sass/utils';

.form {
  display: grid;
  grid-template-columns: repeat(7, 90px);
  gap: utils.spacing(2);
  margin-top: utils.spacing(2);
}

.helpHeader {
  display: flex;
  align-items: center;
  gap: utils.spacing(2);
  justify-content: space-between;
}
