@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  gap: utils.spacing(3);
  justify-content: space-between;
  align-items: flex-start;
}

.divider {
  margin: utils.spacing(0.5, -1, 1, -1);
}

.label {
  margin-block: utils.spacing(2, 1);
}

.groups {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(3);
  padding-bottom: utils.spacing(2);
}

.feedUrlContainer {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
}

.rightSidebar {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.copyFeedButton {
  white-space: nowrap;
  flex: 0 0 auto;
}

.apiAccessHeader {
  margin-top: utils.spacing(2);
}

.feedWidgetHeader {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
}

.widgetPaper {
  padding: utils.spacing(1, 2);
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
}

.accessTokens {
  margin-top: utils.spacing(1);
}
