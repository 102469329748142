@use '@tsp-ui/core/sass/utils';

.root {
  margin-top: utils.spacing(1);
  align-items: center;
}

.editView {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: utils.spacing(2);
  align-items: end;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  width: 350px;
}

.saveButton {
  align-self: flex-end;
}

.divider {
  width: calc(100% - #{utils.spacing(2)});
}

.selectField {
  min-width: 250px;
}

.buttonContainer {
  display: flex;
}
